// extracted by mini-css-extract-plugin
export var alertRed = "Benefits-module--alert-red--b4eac";
export var benefitImage = "Benefits-module--benefitImage--e06f2";
export var bgBarGrey = "Benefits-module--bg-bar-grey--66aa0";
export var bgBeige = "Benefits-module--bg-beige--173f4";
export var bgBeige2 = "Benefits-module--bg-beige2--55bdf";
export var bgCopper = "Benefits-module--bg-copper--387e1";
export var bgDaffodil = "Benefits-module--bg-daffodil--c4975";
export var bgGreyText = "Benefits-module--bg-grey-text--6d34b";
export var bgMing = "Benefits-module--bg-ming--1bc47";
export var bgMint = "Benefits-module--bg-mint--5db20";
export var bgRed = "Benefits-module--bg-red--2d93e";
export var bgRedTrans = "Benefits-module--bg-red-trans--368a8";
export var bgSand = "Benefits-module--bg-sand--d1edf";
export var bgSoftSun = "Benefits-module--bg-softSun--45f88";
export var bgTeal = "Benefits-module--bg-teal--3802b";
export var bgWhite = "Benefits-module--bg-white--35cfd";
export var bgWomenSharp = "Benefits-module--bg-women-sharp--9f688";
export var blackText = "Benefits-module--black-text--9714c";
export var borderMing = "Benefits-module--border-ming--6052f";
export var checkbox = "Benefits-module--checkbox--2b6a2";
export var checked = "Benefits-module--checked--5efaf";
export var copper = "Benefits-module--copper--80573";
export var corners = "Benefits-module--corners--b0471";
export var cursorNormal = "Benefits-module--cursor-normal--407f9";
export var cursorPointer = "Benefits-module--cursor-pointer--25a40";
export var customMarkdown = "Benefits-module--customMarkdown--c9f93";
export var dUnset = "Benefits-module--d-unset--9db23";
export var darkGrey = "Benefits-module--dark-grey--87a80";
export var dash = "Benefits-module--dash--cbaf1";
export var dashBig = "Benefits-module--dashBig--d5f1d";
export var deadSalmon = "Benefits-module--dead-salmon--27392";
export var flex = "Benefits-module--flex--ef21c";
export var flex1 = "Benefits-module--flex-1--dd92b";
export var fontSize12 = "Benefits-module--font-size-12--3cd15";
export var fontSize20 = "Benefits-module--font-size-20--ce4bb";
export var greyPlaceholder = "Benefits-module--grey-placeholder--005d4";
export var greyText = "Benefits-module--grey-text--fa822";
export var h1sizing = "Benefits-module--h1sizing--ab509";
export var h2sizing = "Benefits-module--h2sizing--e62a1";
export var hidden = "Benefits-module--hidden--ab382";
export var image = "Benefits-module--image--eb30a";
export var imageAni = "Benefits-module--imageAni--bb8f9";
export var imageZoom = "Benefits-module--imageZoom--da6da";
export var imageZoomGatsby = "Benefits-module--imageZoomGatsby--3704f";
export var info = "Benefits-module--info--4b7e8";
export var italic = "Benefits-module--italic--9a034";
export var label = "Benefits-module--label--f5c34";
export var lightGrey = "Benefits-module--light-grey--25178";
export var logo = "Benefits-module--logo--44a24";
export var lora = "Benefits-module--lora--888c1";
export var loraBold = "Benefits-module--lora-Bold--ed3db";
export var loraBoldItalic = "Benefits-module--lora-BoldItalic--7413d";
export var loraMedium = "Benefits-module--lora-Medium--eb6d8";
export var loraSemiBold = "Benefits-module--lora-SemiBold--039c3";
export var main = "Benefits-module--main--b048c";
export var ming = "Benefits-module--ming--2cb08";
export var mingHover2 = "Benefits-module--ming-hover-2--ceabf";
export var modalOpen = "Benefits-module--modal-open--2a711";
export var noPointer = "Benefits-module--noPointer--a17f1";
export var noUnderline = "Benefits-module--no-underline--4d01a";
export var openSans = "Benefits-module--openSans--401eb";
export var openSansBold = "Benefits-module--openSans-Bold--b4605";
export var openSansSemiBold = "Benefits-module--openSans-SemiBold--12dca";
export var pageGuide = "Benefits-module--page-guide--70185";
export var popUp = "Benefits-module--popUp--4ccd8";
export var popUpAni = "Benefits-module--pop-up-ani--770f0";
export var quotation = "Benefits-module--quotation--171d8";
export var quotationBenefits = "Benefits-module--quotationBenefits--bb8f7";
export var quotationCard = "Benefits-module--quotationCard--31784";
export var round = "Benefits-module--round--710b4";
export var roundCorners = "Benefits-module--round-corners--41a05";
export var selected = "Benefits-module--selected--bcd88";
export var softCorners = "Benefits-module--soft-corners--75311";
export var softShadows = "Benefits-module--soft-shadows--7ee46";
export var softerCorners = "Benefits-module--softer-corners--14197";
export var softyCorners = "Benefits-module--softy-corners--01510";
export var subtitle = "Benefits-module--subtitle--1947d";
export var tableShadow = "Benefits-module--table-shadow--3e3fd";
export var teal = "Benefits-module--teal--9603f";
export var topNavShadow = "Benefits-module--top-nav-shadow--fbfcc";
export var topicArea = "Benefits-module--topicArea--f6904";
export var uppercase = "Benefits-module--uppercase--a62fc";
export var wFull = "Benefits-module--w-full--c86eb";
export var white = "Benefits-module--white--2adcf";
export var womenRed = "Benefits-module--women-red--ff68e";
export var womenSharp = "Benefits-module--women-sharp--fddb1";