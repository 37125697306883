// extracted by mini-css-extract-plugin
export var alertRed = "Birth-module--alert-red--ee758";
export var bgBarGrey = "Birth-module--bg-bar-grey--53734";
export var bgBeige = "Birth-module--bg-beige--c959c";
export var bgBeige2 = "Birth-module--bg-beige2--63b81";
export var bgCopper = "Birth-module--bg-copper--03174";
export var bgDaffodil = "Birth-module--bg-daffodil--f7de1";
export var bgGreyText = "Birth-module--bg-grey-text--ca6e3";
export var bgMing = "Birth-module--bg-ming--9011d";
export var bgMint = "Birth-module--bg-mint--7b6e5";
export var bgRed = "Birth-module--bg-red--cddc9";
export var bgRedTrans = "Birth-module--bg-red-trans--f1aa1";
export var bgSand = "Birth-module--bg-sand--75f25";
export var bgSoftSun = "Birth-module--bg-softSun--5ec6e";
export var bgTeal = "Birth-module--bg-teal--26b64";
export var bgWhite = "Birth-module--bg-white--b7c7c";
export var bgWomenSharp = "Birth-module--bg-women-sharp--ed03d";
export var birthday = "Birth-module--birthday--a085a";
export var birthdayError = "Birth-module--birthdayError--4ff48";
export var blackText = "Birth-module--black-text--fa36c";
export var borderMing = "Birth-module--border-ming--e6b97";
export var copper = "Birth-module--copper--9b9fb";
export var corners = "Birth-module--corners--a6863";
export var cursorNormal = "Birth-module--cursor-normal--7f347";
export var cursorPointer = "Birth-module--cursor-pointer--cdccb";
export var customMarkdown = "Birth-module--customMarkdown--3a185";
export var dUnset = "Birth-module--d-unset--86351";
export var darkGrey = "Birth-module--dark-grey--4a509";
export var dash = "Birth-module--dash--fc655";
export var dashBig = "Birth-module--dashBig--9d211";
export var dateInput = "Birth-module--dateInput--e5b1f";
export var datepicker = "Birth-module--datepicker--295fc";
export var deadSalmon = "Birth-module--dead-salmon--85272";
export var detailRow = "Birth-module--detailRow--03324";
export var errorMsg = "Birth-module--errorMsg--f6a58";
export var femaleIcon = "Birth-module--femaleIcon--6f733";
export var flex = "Birth-module--flex--bfc12";
export var flex1 = "Birth-module--flex-1--29fa1";
export var fontSize12 = "Birth-module--font-size-12--27aa7";
export var fontSize20 = "Birth-module--font-size-20--249bb";
export var fullWidth = "Birth-module--fullWidth--d9c58";
export var gender = "Birth-module--gender--3c79c";
export var genderError = "Birth-module--genderError--af54f";
export var genderFemale = "Birth-module--genderFemale--13133";
export var genderMale = "Birth-module--genderMale--c9abe";
export var genderSelect = "Birth-module--genderSelect--a70b0";
export var greyPlaceholder = "Birth-module--grey-placeholder--be35c";
export var greyText = "Birth-module--grey-text--d9a54";
export var h1sizing = "Birth-module--h1sizing--884c1";
export var h2sizing = "Birth-module--h2sizing--4e58c";
export var hidden = "Birth-module--hidden--362d1";
export var imageAni = "Birth-module--imageAni--a9d36";
export var imageZoom = "Birth-module--imageZoom--57135";
export var imageZoomGatsby = "Birth-module--imageZoomGatsby--a418a";
export var italic = "Birth-module--italic--8c7ed";
export var lightGrey = "Birth-module--light-grey--3069d";
export var logo = "Birth-module--logo--b9a52";
export var lora = "Birth-module--lora--5fe1c";
export var loraBold = "Birth-module--lora-Bold--a9595";
export var loraBoldItalic = "Birth-module--lora-BoldItalic--9ad3b";
export var loraMedium = "Birth-module--lora-Medium--52f8a";
export var loraSemiBold = "Birth-module--lora-SemiBold--08f11";
export var main = "Birth-module--main--ddcb6";
export var maleIcon = "Birth-module--maleIcon--b6e7b";
export var ming = "Birth-module--ming--14b9a";
export var mingHover2 = "Birth-module--ming-hover-2--4cd86";
export var modalOpen = "Birth-module--modal-open--47daf";
export var noUnderline = "Birth-module--no-underline--5c531";
export var openSans = "Birth-module--openSans--d1600";
export var openSansBold = "Birth-module--openSans-Bold--90a88";
export var openSansSemiBold = "Birth-module--openSans-SemiBold--ee17d";
export var pageGuide = "Birth-module--page-guide--2bce0";
export var popUp = "Birth-module--popUp--f48b9";
export var popUpAni = "Birth-module--pop-up-ani--5e06e";
export var quotation = "Birth-module--quotation--b8a22";
export var quotationBirth = "Birth-module--quotationBirth--ebc5b";
export var rightBorder = "Birth-module--rightBorder--1b273";
export var round = "Birth-module--round--13fc4";
export var roundCorners = "Birth-module--round-corners--199bc";
export var selected = "Birth-module--selected--458b1";
export var slash = "Birth-module--slash--7548c";
export var smallerBox = "Birth-module--smallerBox--533f4";
export var softCorners = "Birth-module--soft-corners--f0c15";
export var softShadows = "Birth-module--soft-shadows--8b059";
export var softerCorners = "Birth-module--softer-corners--d2107";
export var softyCorners = "Birth-module--softy-corners--5f14c";
export var tableShadow = "Birth-module--table-shadow--1239a";
export var teal = "Birth-module--teal--2a853";
export var topNavShadow = "Birth-module--top-nav-shadow--4b3c8";
export var topicArea = "Birth-module--topicArea--dd435";
export var unselected = "Birth-module--unselected--bac42";
export var uppercase = "Birth-module--uppercase--2b79b";
export var wFull = "Birth-module--w-full--c8dbb";
export var white = "Birth-module--white--bd268";
export var womenRed = "Birth-module--women-red--f00d8";
export var womenSharp = "Birth-module--women-sharp--93b8a";