// extracted by mini-css-extract-plugin
export var alertRed = "Residence-module--alert-red--30a0c";
export var bgBarGrey = "Residence-module--bg-bar-grey--7543c";
export var bgBeige = "Residence-module--bg-beige--4c34b";
export var bgBeige2 = "Residence-module--bg-beige2--7f817";
export var bgCopper = "Residence-module--bg-copper--34cac";
export var bgDaffodil = "Residence-module--bg-daffodil--2b013";
export var bgGreyText = "Residence-module--bg-grey-text--9b74f";
export var bgMing = "Residence-module--bg-ming--ff35d";
export var bgMint = "Residence-module--bg-mint--eadf0";
export var bgRed = "Residence-module--bg-red--ff800";
export var bgRedTrans = "Residence-module--bg-red-trans--442cd";
export var bgSand = "Residence-module--bg-sand--5e4df";
export var bgSoftSun = "Residence-module--bg-softSun--7d84a";
export var bgTeal = "Residence-module--bg-teal--6d940";
export var bgWhite = "Residence-module--bg-white--c37af";
export var bgWomenSharp = "Residence-module--bg-women-sharp--b8298";
export var blackText = "Residence-module--black-text--5aa17";
export var borderMing = "Residence-module--border-ming--1198f";
export var copper = "Residence-module--copper--ebda5";
export var corners = "Residence-module--corners--33186";
export var cursorNormal = "Residence-module--cursor-normal--37c70";
export var cursorPointer = "Residence-module--cursor-pointer--44c28";
export var customMarkdown = "Residence-module--customMarkdown--d98bd";
export var dUnset = "Residence-module--d-unset--3d465";
export var darkGrey = "Residence-module--dark-grey--9ed0f";
export var dash = "Residence-module--dash--b04e5";
export var dashBig = "Residence-module--dashBig--6b98d";
export var deadSalmon = "Residence-module--dead-salmon--e7823";
export var flex = "Residence-module--flex--723ee";
export var flex1 = "Residence-module--flex-1--0c120";
export var fontSize12 = "Residence-module--font-size-12--30124";
export var fontSize20 = "Residence-module--font-size-20--e7bc1";
export var greyPlaceholder = "Residence-module--grey-placeholder--7dca7";
export var greyText = "Residence-module--grey-text--b2073";
export var h1sizing = "Residence-module--h1sizing--1f36b";
export var h2sizing = "Residence-module--h2sizing--39882";
export var hidden = "Residence-module--hidden--73e69";
export var imageAni = "Residence-module--imageAni--37867";
export var imageZoom = "Residence-module--imageZoom--72998";
export var imageZoomGatsby = "Residence-module--imageZoomGatsby--d579e";
export var italic = "Residence-module--italic--2ca3e";
export var lightGrey = "Residence-module--light-grey--608bb";
export var listWrapper = "Residence-module--listWrapper--55948";
export var listbox = "Residence-module--listbox--03da9";
export var logo = "Residence-module--logo--806c7";
export var lora = "Residence-module--lora--849d7";
export var loraBold = "Residence-module--lora-Bold--a5537";
export var loraBoldItalic = "Residence-module--lora-BoldItalic--a194e";
export var loraMedium = "Residence-module--lora-Medium--3471b";
export var loraSemiBold = "Residence-module--lora-SemiBold--6b1e5";
export var main = "Residence-module--main--7a434";
export var ming = "Residence-module--ming--ecf71";
export var mingHover2 = "Residence-module--ming-hover-2--44b55";
export var modalOpen = "Residence-module--modal-open--5cd01";
export var noUnderline = "Residence-module--no-underline--30a1d";
export var openSans = "Residence-module--openSans--2b254";
export var openSansBold = "Residence-module--openSans-Bold--6f60d";
export var openSansSemiBold = "Residence-module--openSans-SemiBold--a0c83";
export var pageGuide = "Residence-module--page-guide--c7d85";
export var popUp = "Residence-module--popUp--0ba3a";
export var popUpAni = "Residence-module--pop-up-ani--faa90";
export var quotation = "Residence-module--quotation--72d23";
export var quotationResidence = "Residence-module--quotationResidence--bd67c";
export var round = "Residence-module--round--232f2";
export var roundCorners = "Residence-module--round-corners--4eae9";
export var softCorners = "Residence-module--soft-corners--f2a80";
export var softShadows = "Residence-module--soft-shadows--19b75";
export var softerCorners = "Residence-module--softer-corners--04da8";
export var softyCorners = "Residence-module--softy-corners--36730";
export var tableShadow = "Residence-module--table-shadow--e25df";
export var teal = "Residence-module--teal--e289f";
export var topNavShadow = "Residence-module--top-nav-shadow--fbea0";
export var topicArea = "Residence-module--topicArea--b0873";
export var uppercase = "Residence-module--uppercase--aa18c";
export var wFull = "Residence-module--w-full--34d12";
export var white = "Residence-module--white--92217";
export var womenRed = "Residence-module--women-red--46993";
export var womenSharp = "Residence-module--women-sharp--8b217";