// extracted by mini-css-extract-plugin
export var alertRed = "quotation-module--alert-red--595cf";
export var bgBarGrey = "quotation-module--bg-bar-grey--e9d85";
export var bgBeige = "quotation-module--bg-beige--d49d1";
export var bgBeige2 = "quotation-module--bg-beige2--8f308";
export var bgCopper = "quotation-module--bg-copper--9efab";
export var bgDaffodil = "quotation-module--bg-daffodil--7fc7c";
export var bgGreyText = "quotation-module--bg-grey-text--ad0e1";
export var bgMing = "quotation-module--bg-ming--b9940";
export var bgMint = "quotation-module--bg-mint--d8adc";
export var bgRed = "quotation-module--bg-red--098f9";
export var bgRedTrans = "quotation-module--bg-red-trans--ab39d";
export var bgSand = "quotation-module--bg-sand--45b79";
export var bgSoftSun = "quotation-module--bg-softSun--927a2";
export var bgTeal = "quotation-module--bg-teal--2701c";
export var bgWhite = "quotation-module--bg-white--1ad2b";
export var bgWomenSharp = "quotation-module--bg-women-sharp--7beb8";
export var blackText = "quotation-module--black-text--e21ef";
export var borderMing = "quotation-module--border-ming--6daca";
export var content = "quotation-module--content--4b093";
export var copper = "quotation-module--copper--b9d93";
export var corners = "quotation-module--corners--7b285";
export var cursorNormal = "quotation-module--cursor-normal--2b02b";
export var cursorPointer = "quotation-module--cursor-pointer--e8b42";
export var customMarkdown = "quotation-module--customMarkdown--44f2a";
export var dUnset = "quotation-module--d-unset--e1a02";
export var darkGrey = "quotation-module--dark-grey--d3d6c";
export var dash = "quotation-module--dash--ba3f4";
export var dashBig = "quotation-module--dashBig--19ee2";
export var deadSalmon = "quotation-module--dead-salmon--eef05";
export var errorMsg = "quotation-module--errorMsg--5d7b4";
export var faqBody = "quotation-module--faq-body--abfdb";
export var flex = "quotation-module--flex--2ad18";
export var flex1 = "quotation-module--flex-1--b6e50";
export var fontSize12 = "quotation-module--font-size-12--5b2c4";
export var fontSize20 = "quotation-module--font-size-20--72d5b";
export var glossaryItem = "quotation-module--glossary-item--7f689";
export var glossaryList = "quotation-module--glossary-list--55a3b";
export var greyPlaceholder = "quotation-module--grey-placeholder--6b187";
export var greyText = "quotation-module--grey-text--3dae5";
export var h1sizing = "quotation-module--h1sizing--9e832";
export var h2sizing = "quotation-module--h2sizing--dcb3c";
export var header = "quotation-module--header--5e8a9";
export var hidden = "quotation-module--hidden--132e2";
export var imageAni = "quotation-module--imageAni--f8ed4";
export var imageZoom = "quotation-module--imageZoom--54fb2";
export var imageZoomGatsby = "quotation-module--imageZoomGatsby--38b25";
export var italic = "quotation-module--italic--b1c32";
export var left = "quotation-module--left--0abb2";
export var leftCol = "quotation-module--left-col--0c0b9";
export var leftEmpty = "quotation-module--leftEmpty--c1910";
export var leftFull = "quotation-module--leftFull--399f3";
export var lightGrey = "quotation-module--light-grey--864dd";
export var logo = "quotation-module--logo--60b4f";
export var lora = "quotation-module--lora--75456";
export var loraBold = "quotation-module--lora-Bold--cbc99";
export var loraBoldItalic = "quotation-module--lora-BoldItalic--891be";
export var loraMedium = "quotation-module--lora-Medium--0e37b";
export var loraSemiBold = "quotation-module--lora-SemiBold--7ff34";
export var main = "quotation-module--main--b9d30";
export var ming = "quotation-module--ming--0c595";
export var mingHover2 = "quotation-module--ming-hover-2--b9698";
export var modalOpen = "quotation-module--modal-open--6bd9d";
export var noUnderline = "quotation-module--no-underline--a3222";
export var openSans = "quotation-module--openSans--12ccf";
export var openSansBold = "quotation-module--openSans-Bold--ed3cb";
export var openSansSemiBold = "quotation-module--openSans-SemiBold--06b22";
export var pageGuide = "quotation-module--page-guide--f2c19";
export var popUp = "quotation-module--popUp--2ae9f";
export var popUpAni = "quotation-module--pop-up-ani--0045c";
export var quotation = "quotation-module--quotation--59e52";
export var quotationBody = "quotation-module--quotationBody--8019a";
export var right = "quotation-module--right--3f061";
export var round = "quotation-module--round--7ebd6";
export var roundCorners = "quotation-module--round-corners--d734f";
export var softCorners = "quotation-module--soft-corners--6a807";
export var softShadows = "quotation-module--soft-shadows--8a588";
export var softerCorners = "quotation-module--softer-corners--aab96";
export var softyCorners = "quotation-module--softy-corners--e07d0";
export var subtitle = "quotation-module--subtitle--f06a0";
export var tableShadow = "quotation-module--table-shadow--d810d";
export var teal = "quotation-module--teal--6b329";
export var topNavShadow = "quotation-module--top-nav-shadow--60284";
export var topicArea = "quotation-module--topicArea--a7b47";
export var uppercase = "quotation-module--uppercase--387a2";
export var wFull = "quotation-module--w-full--b65f4";
export var white = "quotation-module--white--7e9eb";
export var womenRed = "quotation-module--women-red--81b25";
export var womenSharp = "quotation-module--women-sharp--e0a6f";